import { QualityType } from '@procsea/design-system'

import { Calibre } from './calibre'
import { Currency } from './currency'
import { FishingMethod } from './fishingMethod'
import { FishingPort } from './fishingPort'
import { FishingZone } from './fishingZone'
import { GroupProductLines } from './groupProductLines'
import { Label } from './label'
import { ParcelGroup } from './parcel'
import { FormattedPermissions, Permissions } from './permissions'
import { Preparation } from './preparation'
import { ConservationMethod, Variety } from './productTemplate'
import { UserType } from './router'
import { SpiUnit } from './sellerProductInstance'
import { SPIType } from './spiType'
import { Unit } from './unit'

export enum OrderItemStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}

export enum OrderItemResolution {
  BUYER_CANCELLATION = 'buyer_cancellation',
  CENTRAL_CANCELLATION = 'centrale_cancellation',
  CANT_BE_SHIPPED = 'cant_be_shipped',
  INPUT_ERROR = 'input_error',
  OUT_OF_STOCK = 'out_of_stock',
  PRODUCT_REPLACED = 'product_replaced',
  REDELIVERY = 'redelivery',
  RETURN_OF_MERCHANDISE = 'return_of_merchandise',
  SELLER_CANCELLATION = 'seller_cancellation',
  UNAVAILABLE = 'unavailable',
}

export type OrderItemActions = 'add_order_item' | 'approve' | 'cancel' | 'prepare' | 'update'
export type OrderItemAttributes =
  | 'buyer_company_id'
  | 'comment'
  | 'delivery_date'
  | 'delivery_location_id'
  | 'entered_price_unit_id'
  | 'gln_association_id'
  | 'items'
  | 'marketplace_id'
  | 'po_number'
  | 'price'
  | 'printed'
  | 'provider_comment_delivery_note'
  | 'provider_internal_comment_order_note'
  | 'quantity_unit_id'
  | 'quantity'
  | 'shipping_date'
  | 'shipping_location_id'
  | 'status'
  | 'tour_id'
  | 'tour_pickup_time'

export type OrderItemFormattedActions = 'addOrderItem' | 'approve' | 'cancel' | 'prepare' | 'update'
export type OrderItemFormattedAttributes =
  | 'buyerCompanyId'
  | 'comment'
  | 'deliveryDate'
  | 'deliveryLocationId'
  | 'enteredPriceUnitId'
  | 'glnAssociationId'
  | 'items'
  | 'marketplaceId'
  | 'poNumber'
  | 'price'
  | 'printed'
  | 'providerCommentDeliveryNote'
  | 'providerInternalCommentOrderNote'
  | 'quantity'
  | 'quantityUnitId'
  | 'shippingDate'
  | 'shippingLocationId'
  | 'status'
  | 'tourId'
  | 'tourPickupTime'

export type FormattedOrderItemPermissions = FormattedPermissions<
  OrderItemFormattedActions,
  OrderItemFormattedAttributes
>

export interface OriginSpiAPI {
  id: Id
  quantity: number
  unit_id: Id
  is_published: boolean
  is_frequent?: boolean
}

export interface OriginSpi {
  id: Id
  quantity: number
  unitId: Id
  isPublished: boolean
  isFrequent?: boolean
}

export type BuyerOrderItemActions =
  | 'add_dispatch_quantities'
  | 'cancel'
  | 'update'
  | 'update_dispatch_quantities'

export type BuyerOrderItemAttributes =
  | 'comment'
  | 'conservation_method_id'
  | 'entered_price_unit_id'
  | 'price'
  | 'quantity'
  | 'quantity_unit_id'
  | 'provider_comment_delivery_note'
  | 'provider_internal_comment_order_note'

export type BuyerOrderItemFormattedActions =
  | 'addDispatchQuantities'
  | 'cancel'
  | 'update'
  | 'updateDispatchQuantities'

export type BuyerOrderItemFormattedAttributes =
  | 'comment'
  | 'conservationMethodId'
  | 'enteredPriceUnitId'
  | 'price'
  | 'quantity'
  | 'quantityUnitId'
  | 'providerCommentDeliveryNote'
  | 'providerInternalCommentOrderNote'

export type FormattedBuyerOrderItemPermissions = FormattedPermissions<
  BuyerOrderItemFormattedActions,
  BuyerOrderItemFormattedAttributes
>

export interface OrderItemBase {
  brand: string | null
  breedId: Id | null
  calibreId: Id | null
  comment: string | null
  conservationMethod: ConservationMethod | null
  customerAskedPrice: number | null
  customerAskedPriceUnitId: Id | null
  fishingMethodId: Id | null
  fishingPortId: Id | null
  fishingZoneId: Id | null
  groupProductLines: GroupProductLines[]
  gtin: string | null
  id: Id
  labelIds: Id[]
  longDescription: string
  manorArticleNumber: string | null
  manorGrpcNumber: number | null
  maturation: number | null
  numberOfParcels: number
  orderId: Id
  originSellerCompanyId: Id
  originSellerProductInstanceId: Id
  packageQuantity: number | null
  packageQuantityUnitId: Id | null
  packageUnitId: Id | null
  possibleOrigins: Id[]
  preparationId: Id | null
  presentationIds: Id[]
  price: number | null
  priceOfTheDay: number | null
  priceOfTheDayUnitId: Id | null
  priceSeenByCustomerAtOrderCreation: number | null
  priceSeenByCustomerAtOrderCreationUnitId: Id | null
  productName: string
  provenance: string | null
  quality: QualityType | null
  qualityCategoryId: Id | null
  quantity: number
  quantityInPriceUnit: number
  quantityIsSynchronizedWithDispatches: boolean
  quantityUnitId: Id
  quantityValidated: number | null
  rangeIds: Id[]
  resolution: OrderItemResolution | null
  selfService: boolean
  sellerDesignation: string | null
  sellerProductInstanceFromBuyerExternalId: string | null
  sellerProductInstanceType: SPIType
  specificationIds: Id[]
  status: OrderItemStatus
  statusChangedAt: ApiDate | null
  statusChangedBy: string | null
  storageLocation: string | null
  validatedQuantity: number | null
  validatedQuantityUnitId: Id | null
  variantNumber: string | null
  variety: Variety | null
}

export interface BuyerOrderItemBase extends OrderItemBase {
  created: string
  currencyId: Id
  permissions: Permissions<BuyerOrderItemActions, BuyerOrderItemAttributes>
  shouldCheckForStock: boolean
}

export interface BuyerOrderItem extends BuyerOrderItemBase {
  enteredPriceUnitId: Id | null
  fixedShippingDateId: Id | null
  originSellerProductInstance: undefined
  priceUnitId: Id
  type: UserType.BUYER
}

export interface BuyerOrderItemAggregated extends BuyerOrderItemBase {
  calibre?: Calibre
  currency?: Currency
  fishingMethod?: FishingMethod
  fishingPort?: FishingPort
  fishingZone?: FishingZone
  labels: Label[]
  packageQuantityUnit?: Unit
  preparation?: Preparation
  priceUnit?: Unit
  quantityUnit?: Unit
}

export interface SellerDirectOrderItem {
  [key: string]: any
  bestBeforeDate?: string | null
  comment?: string | null
  enteredPrice: number | null
  enteredPriceUnitId: number | null
  id?: Id
  originSellerProductInstanceId?: Id
  packagingDate?: ApiDate | null
  preparationId: Id | null
  quantity: number
  quantityUnitId: number
  status: OrderItemStatus
}

export type RequiredFields = (keyof OrderItemBase)[]

export interface SellerOrderItemBase extends OrderItemBase {
  bestBeforeDate: string | null
  buyingPrice: number | null
  buyingPriceOfTheDay: number | null
  customerDiscountAmount: number
  customerDiscountRate: number
  dropShippingSupplierExternalReference: string | null
  dropShippingSupplierName: string | null
  enteredPrice: number | null
  gtin: string | null
  latinName: string
  manorArticleNumber: string | null
  originSellerProductInstance: OriginSpi | null
  packageWeight: number | null
  packagingDate: ApiDate | null
  parcelGroups: ParcelGroup[]
  permissions: Permissions<OrderItemActions, OrderItemAttributes>
  productAttributesRequiredAtValidation: RequiredFields
  prospectivePrice: number | null
  providerCommentDeliveryNote: string | null
  providerInternalCommentOrderNote: string | null
  quantityInvoiced: number
  selectablePreparationIds: Id[]
  selectableUnits: SpiUnit[]
  sellerPrice: number | null
  sellerProductId: Id
  unitaryPrice: number
  variantNumber: string | null
  weightGross: number
}

export interface SellerOrderItem extends SellerOrderItemBase {
  enteredPriceUnitId: Id | null
  fixedShippingDateId: Id | null
  priceUnitId: Id
  prospectivePriceUnitId: Id | null
  type: UserType.SELLER
}

export interface SellerOrderItemAggregated extends SellerOrderItem {
  calibre?: Calibre
  deliveryLocationId?: Id | null
  enteredPriceUnit?: Unit
  fishingMethod?: FishingMethod
  fishingPort?: FishingPort
  fishingZone?: FishingZone
  packageQuantityUnit?: Unit
  preparation?: Preparation
  prospectivePriceUnit?: Unit
  quantityUnit?: Unit
  shippingLocationId?: Id | null
}

export interface AcceptedOrderItemAggregated extends SellerOrderItemAggregated {
  status: OrderItemStatus.ACCEPTED
}

export interface CancelOrderItemPayload {
  status: OrderItemStatus
  resolution: OrderItemResolution
}

export interface SellerOrderItemRequestBody {
  comment?: string
  enteredPrice: number | null
  enteredPriceUnitId: Id
  orderId: Id
  originSellerProductInstanceId: Id
  preparationId: Id | null
  providerCommentDeliveryNote?: string
  providerInternalCommentOrderNote?: string
  quantity: number
  quantityUnitId: Id
}

export interface EditOrderItemFormValue {
  id: Id
  enteredPrice: number
  enteredPriceUnitId: Id
  quantity: number
  quantityUnitId: Id
}
